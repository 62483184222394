<template lang="html">
    <div class="b-location">
      <div class="form-group">
        <!-- <label v-if="label">{{label}}</label> -->
        <div v-if="skuID" class="form-control" v-bind:class="disabled?'disabled':''" :title="content?content.node_name:placeholder">{{content.node_name?content.node_name:placeholder}}</div>
        <div v-else class="form-control" v-bind:class="disabled?'disabled':''" :title="content?content.sku_name:placeholder">{{content.sku_name?content.sku_name:placeholder}}</div>
        <i class="cis-search" @click="switchFn(skuID)" v-bind:class="disabled?'disabled':''"/>
      </div>
      <CModal v-if="modalVisible"
        :title="title?title:(placeholder?placeholder:'Enter values')"
        :show.sync="modalVisible"
        size="lg">
        <CRow>
          <CCol>
            <div>
              <label class="typo__label" v-if="label">Category</label>
              <multiselect v-model="selectedCategory" placeholder="Select Category" :options="skuCategory"  @input="updateSkuCategory" label="name" track-by="id" :searchable="false" :close-on-select="true" :show-labels="false" :disabled="disabled||skuID"></multiselect>
            </div>
          </CCol>
          <CCol>
            <div>
              <label class="typo__label" v-if="label">SKU Code</label>
              <multiselect v-model="selectedSku" placeholder="Select SKUs" :options="skuList" @input="updateSku" label="name" track-by="id" :searchable="true" :close-on-select="true" :show-labels="false" :disabled="disabled||skuID"></multiselect>
            </div>
          </CCol>
          <CCol>
            <div>
              <label class="typo__label" v-if="label">SKU Name</label>
              <multiselect v-model="selectedSku" placeholder="Select SKUs" :options="skuList" @input="updateSku" label="name" track-by="id" :searchable="true" :close-on-select="true" :show-labels="false" :disabled="disabled||skuID"></multiselect>
            </div>
          </CCol>
          <!-- <CCol>
            <div>
              <label class="typo__label" v-if="label">Type</label>
              <multiselect v-model="selectedType" placeholder="Select Type" :options="nodeType" label="name" track-by="id" :searchable="false" :close-on-select="true" :show-labels="false" :disabled="true"></multiselect>
            </div>
          </CCol> -->
     
        </CRow>
        <CRow v-if="skuID">
          <CCol>
            <div>
              <label class="typo__label" v-if="title">Location</label>
              <multiselect v-model="selectedNodeLocation" placeholder="Select Location" :options="locationList" @input="updateLocation" label="name" track-by="id" :searchable="true" :close-on-select="true" :show-labels="false" :disabled="disabled"></multiselect>
            </div>
          </CCol>
          <CCol>
            <div>
              <label class="typo__label" v-if="title">Nodes</label>
              <multiselect v-model="selectedNode" placeholder="Select Nodes" :options="nodeList" @input="updateNode" label="code" track-by="id" :searchable="true" :close-on-select="true" :show-labels="false" :disabled="disabled"></multiselect>
            </div>
          </CCol>
          <CCol>
            <div>
              <label class="typo__label" v-if="title">Type</label>
              <multiselect v-model="selectedNodeType" placeholder="Select Type" :options="nodeType" label="name" track-by="id" :searchable="false" :close-on-select="true" :show-labels="false" :disabled="true"></multiselect>
            </div>
          </CCol>
          <CCol>
            <div>
              <label class="typo__label" v-if="title">Category</label>
              <multiselect v-model="selectedNodeCategory" placeholder="Select Category" :options="nodeCategory"  label="name" track-by="id" :searchable="false" :close-on-select="true" :show-labels="false" :disabled="true"></multiselect>
            </div>
          </CCol>
          <br/>
        </CRow>
        <template #footer>
          <CButton class="btn-primary" @click="reset()">Reset</CButton>
          <CButton class="btn-danger" @click="close()">Close</CButton>
          <CButton class="btn-success" @click="select()">Select</CButton>
        </template>
      </CModal>
    </div>
  </template>
  
  <script>
  import Multiselect from 'vue-multiselect';
  //import * as locationsTypeAPI from '@common/utils/api/masters/locationType';
  import * as nodeAPI from '@common/utils/api/masters/nodes';
  import * as LocationAPI from '@common/utils/api/masters/locations';
  import * as skuAPI from '@common/utils/api/masters/sku';
  import * as nodeSkuAPI from '@common/utils/api/masters/nodeskus';
  import * as categoryAPI from '@common/utils/api/masters/skuCategory';
  export default {
      name: 'BrdgeSkuSelector',
      components: { Multiselect },
      props: ['skuID','label', 'placeholder', 'disabled'],
      data () {
          return {
            selectedType: null,
            selectedCategory: null,
            selectedLocation: null,
            selectedSku: null,
            selectedNodeType: null,
            selectedNodeCategory: null,
            selectedNodeLocation: null,
            selectedNode: null,
            content: {},
            modalVisible: false,
            nodes:[],
            nodeList:[],
            locationList:[],
            nodeType:[],
            nodeCategory:[],
            skuCategory:[],
            sku:[],
            skuList:[],
            title:'',
            selectedNode:null
          }
      },
      watch:{
        skuID:function(){
        console.log("Sku Id:",this.skuID);
      }
    },
      mounted: function(){
        //this.content = this.value;
        //this.getLocationsType();
        this.getLocation();
        this.getSku();
        this.getSkuCategory();
      },
      methods: {
        reset:function(){
           if(this.skuID){
            this.selectedNodeType = {},
            this.selectedNodeCategory = {},
            this.selectedNodeLocation = {},
            this.selectedNode = {},
            this.getFromNodeSku(this.skuID);
           }
           else{
            this.selectedCategory = {},
            this.selectedSku = {},
            this.getSku();
           }
        },
        switchFn:function(id){
           this.modalVisible = true;
           if(id){
            this.title = 'NodeSKU';
            let filteredSkuList = this.sku.filter(item => {
              return item.id == id;
            });
            console.log("Filtered Data:",filteredSkuList[0]);
               this.selectedSku  = filteredSkuList.length ? filteredSkuList[0] : {};
            // this.selectedType = filterednodeList[0].nodeType
              this.selectedCategory = filteredSkuList[0].category;
            // this.selectedLocation = filterednodeList[0].locationDetails;
            this.content.sku_id = this.selectedSku.id;
            this.content.sku_name = this.selectedSku.name;
            this.getFromNodeSku(id);
           }
           else{
            this.title = 'SKU';
           }
          
        },
        getSkuCategory:function(){
            Promise.allSettled([categoryAPI.fetchList()]).then(values=>{
            if(values[0].status == 'fulfilled'){
              let list = values[0].value.data.payload;
              this.skuCategory = list
            }
          });
        },
        updateSkuCategory:function(){
            this.selectedNodeType = {},
            this.selectedNodeCategory = {},
            this.selectedNode = {},
            this.selectedSku = {}
           if(this.selectedCategory){
            let skuList = this.skuList;
            let filteredSkuList = skuList.filter(item => {
              return item.category.id == this.selectedCategory.id;
            });
            console.log("Filtered SKU Category List:",filteredSkuList);
            this.skuList = filteredSkuList.length ? filteredSkuList : [];
          }
        },
        updateNode:function(){
          if(this.selectedNode){
            this.selectedNodeType = this.selectedNode.nodeType
            this.selectedNodeCategory = this.selectedNode.nodeCategory;
            this.selectedNodeLocation = this.selectedNode.locationDetails;
            this.content.node_id = this.selectedNode.id;
            this.content.node_name = this.selectedNode.name;
            console.log("Selected Node:",this.selectedNode);
          } else {
            this.content = {
              node_id: '',
              node_name: ''
            };
          }
        },
        updateLocation: function(){
          console.log("Filtered Node List 1:",this.nodeList);
            this.selectedNodeType = {},
            this.selectedNodeCategory = {},
            this.selectedNode = {}
           if(this.selectedNodeLocation){
            let nodeList = this.nodeList;
            let filterednodeList = nodeList.filter(item => {
              return item.location == this.selectedNodeLocation.id;
            });
            console.log("Filtered Node List:",filterednodeList);
            this.nodeList = filterednodeList.length ? filterednodeList : [];
          }
        },
        getFromNodeSku:function(id){
          Promise.allSettled([nodeSkuAPI.getNodesBySkuId(id)]).then(values=>{
            if(values[0].status == 'fulfilled'){
              let list = values[0].value.data.payload;
              console.log("Node By Sku ID From NodeSku:",list);
              this.nodeList = [];
              for(let node of list){
                 this.nodeList.push(node.nodeDetails);
              }
            }
          });
        },
        getSku:function(){
            //fetchList
            Promise.allSettled([skuAPI.fetchList()]).then(values=>{
            if(values[0].status == 'fulfilled'){
              let list = values[0].value.data.payload;
              this.sku = list;
              this.skuList = list;
              console.log("SKU List Fetched:",this.sku);
            }
          });
        },
        getLocation:function(){
            Promise.allSettled([LocationAPI.allLocationList()]).then(values=>{
            if(values[0].status == 'fulfilled'){
              let list = values[0].value.data.payload;
              this.locationList = list
            }
          });
        },
        select: function(){
          this.modalVisible = false;
          this.$emit('selectedLocation', this.content);
          console.log("Data Emitted:",this.content);
        },
        close: function(){
          this.modalVisible = false;
        //   this.setContent(this.value);
        },
      
      
        updateSku: function(){
          if(this.selectedSku){
            //this.selectedType = this.selectedNode.nodeType
            this.selectedCategory = this.selectedSku.category;
            // this.selectedLocation = this.selectedNode.locationDetails;
            this.content.sku_id = this.selectedSku.id;
            this.content.sku_name = this.selectedSku.name;
            console.log("Selected sku:",this.selectedSku);
          } else {
            this.content = {
              id: '',
              name: ''
            };
          }
        }
      },
  
  }
  </script>
  
  <style lang="css" scoped>
  .b-location .form-group {
    position: relative;
    margin-bottom: 0 !important;
  }
  .b-location .form-group .form-control{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 35px;
  }
  .b-location .form-group i{
    font-size: 24px;
    position: absolute;
    bottom: 0;
    right: 0px;
    cursor: pointer;
    height: 38px;
    vertical-align: middle;
    display: flex;
    align-items: center;
    width: 35px;
    padding-left: 5px;
    border-left: 1px solid #ced4da;
  }
  .b-location .multiselect__content-wrapper {
      width: auto !important;
  }
  </style>
  