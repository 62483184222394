<template lang="html">
    <div class="b-location">
      <div class="form-group">
        <!-- <label v-if="title">{{title}}</label> -->
        <div v-if="nodeID" class="form-control" v-bind:class="disabled?'disabled':''" :title="content?content.sku_name:placeholder">{{content.sku_name?content.sku_name:placeholder}}</div>
        <div v-else class="form-control" @click="switchFn(nodeID)" v-bind:class="disabled?'disabled':''" :title="content.node_name?content.node_name:placeholder">{{content.node_name?content.node_name:placeholder}}</div>
        <i class="cis-search" @click="switchFn(nodeID)" v-bind:class="disabled?'disabled':''"/>
      </div>
      <CModal v-if="modalVisible"
        :title="title?title:(placeholder?placeholder:'Enter values')"
        :show.sync="modalVisible"
        size="lg">
        <CRow>
          <CCol>
            <div>
              <label class="typo__label" v-if="title">Location</label>
              <multiselect v-model="selectedLocation" placeholder="Select Location" :options="locationList" @input="updateLocation" label="name" track-by="id" :searchable="true" :close-on-select="true" :show-labels="false" :disabled="disabled || nodeID"></multiselect>
            </div>
            <!-- <b-location-selector
                :disabled="disabled"
                label="Location"
                placeholder=""
                v-model="selectedLocation"
                v-on="$listeners"
                @selectedLocation="updateLocation"
              /> -->
          </CCol>
          <CCol>
            <div>
              <label class="typo__label" v-if="title">Nodes</label>
              <multiselect v-model="selectedNode" placeholder="Select Node" :options="nodeList" @input="updateNode" label="name" track-by="id" :searchable="true" :close-on-select="true" :show-labels="false" :disabled="disabled || nodeID"></multiselect>
            </div>
          </CCol>
          <CCol>
            <div>
              <label class="typo__label" v-if="title">Type</label>
              <multiselect v-model="selectedType" placeholder="Select Type" :options="nodeType" label="name" track-by="id" :searchable="false" :close-on-select="true" :show-labels="false" :disabled="true"></multiselect>
            </div>
          </CCol>
          <CCol>
            <div>
              <label class="typo__label" v-if="title">Category</label>
              <multiselect v-model="selectedCategory" placeholder="Select Category" :options="nodeCategory"  label="name" track-by="id" :searchable="false" :close-on-select="true" :show-labels="false" :disabled="true"></multiselect>
            </div>
          </CCol>
          <br/>
        </CRow>
        <CRow v-if="nodeID">
          <CCol>
            <div>
              <label class="typo__label" v-if="title">SKU Code</label>
              <multiselect v-model="selectedSku" placeholder="Select SKU" :options="skuList" @input="updateSku" label="code" track-by="id" :searchable="true" :close-on-select="true" :show-labels="false" :disabled="disabled"></multiselect>
            </div>
          </CCol>
          <CCol>
            <div>
              <label class="typo__label" v-if="title">SKU Name</label>
              <multiselect v-model="selectedSku" placeholder="Select SKU" :options="skuList" @input="updateSku" label="name" track-by="id" :searchable="true" :close-on-select="true" :show-labels="false" :disabled="disabled"></multiselect>
            </div>
          </CCol>
          </CRow>
        <template #footer>
          <CButton class="btn-danger" @click="close()">Close</CButton>
          <CButton class="btn-success" @click="select()">Select</CButton>
        </template>
      </CModal>
    </div>
  </template>
  
  <script>
  import Multiselect from 'vue-multiselect';
  //import * as locationsTypeAPI from '@common/utils/api/masters/locationType';
  import * as nodeAPI from '@common/utils/api/masters/nodes';
  import * as LocationAPI from '@common/utils/api/masters/locations';
  import * as nodeSkuAPI from '@common/utils/api/masters/nodeskus';
  export default {
      name: 'BrdgeNodeSelector',
      components: { Multiselect },
      props: ['nodeID','placeholder', 'disabled'],
      data () {
          return {
            selectedType: null,
            selectedCategory: null,
            selectedLocation: null,
            selectedNode: null,
            zone: {},
            state: {},
            city: {},
            area: {},
            content: {},
            modalVisible: false,
            nodes:[],
            nodeList:[],
            locationList:[],
            nodeType:[],
            nodeCategory:[],
            skuList:[],
            skus:[],
            title:'',
            selectedSku:null,
          }
      },
    watch:{
      nodeID:function(){
        console.log("Node Id:",this.nodeID);
        //this.selectedNode = this.nodeID;
   
      }
    },
      mounted: function(){
        //this.content = this.value;
        //this.getLocationsType();
        this.getNodes();
        this.getLocation();
   
      },
      methods: {
        switchFn:function(id){
           this.modalVisible = true;
           if(id){
            this.title = 'NodeSKU';
            let filterednodeList = this.nodes.filter(item => {
              return item.id == id;
            });
            console.log("Filtered Data:",filterednodeList[0]);
            this.selectedNode  = filterednodeList.length ? filterednodeList[0] : {};
            this.selectedType = filterednodeList[0].nodeType
            this.selectedCategory = filterednodeList[0].nodeCategory;
            this.selectedLocation = filterednodeList[0].locationDetails;
            this.content.node_id = this.selectedNode.id;
            this.content.node_name = this.selectedNode.name;
            this.getFromNodeSku(id);
           }
           else{
            this.title = 'Node';
           }
          
        },
        reset:function(){
           if(this.nodeID){
            this.selectedSku = {},
            this.getFromNodeSku(this.nodeID);
           }
           else{
            this.selectedType = {},
            this.selectedCategory = {},
            this.selectedLocation = {},
            this.selectedNode = {},
            this.getNodes();
           }
        },
        getFromNodeSku:function(id){
          Promise.allSettled([nodeSkuAPI.fetchSKUsByNodeId(id)]).then(values=>{
            if(values[0].status == 'fulfilled'){
              let list = values[0].value.data.payload;
              console.log("SKU By Node ID From NodeSku:",list);
              //this.skus = list
              this.skuList = [];
              for(let sku of list){
                 this.skuList.push(sku.skuDetails);
              }
            }
          });
        },
        updateSku: function(){
          if(this.selectedSku){
            this.content.sku_id = this.selectedSku.id;
            this.content.sku_name = this.selectedSku.name;
            console.log("Selected sku:",this.selectedSku);
          } else {
            this.content = {
              id: '',
              name: ''
            };
          }
        },
        getNodes:function(){
            console.log("Mounted in Node Slector Called.",this.nodeID);
            Promise.allSettled([nodeAPI.fetchList()]).then(values=>{
            if(values[0].status == 'fulfilled'){
              let list = values[0].value.data.payload;
              this.nodes = list;
              this.nodeList = list
             //console.log("Mounted in Node Slector Called:",list);
             for(let list of this.nodeList){
                //  this.nodeType.push(list.nodeType);
                //  this.nodeCategory.push(list.nodeCategory);
                //  console.log("Mounted in Node Slector 1:",list.locationDetails);
                //   if( this.locationList.length > 0){
                //     this.locationList.forEach(element => {
                //     console.log(" Called 2:",element.id);
                //     console.log(" Called 3:",list.locationDetails.id);
                //     if(list.locationDetails.id != element.id){
                //         this.locationList.push(list.locationDetails);
                //         console.log("If Called");
                //     }
                //     else{
                //         console.log("Else Called");
                //     }
                //  });
                //   }
                //   else{
                //     this.locationList.push(list.locationDetails);
                //   }
                
                 
                };
            }
          });
        },
        getLocation:function(){
            Promise.allSettled([LocationAPI.allLocationList()]).then(values=>{
            if(values[0].status == 'fulfilled'){
              let list = values[0].value.data.payload;
              this.locationList = list
            }
          });
        },
        select: function(){
          this.modalVisible = false;
          this.$emit('selectedLocation', this.content);
          console.log("Data Emitted:",this.content);
        },
        close: function(){
          this.modalVisible = false;
        //   this.setContent(this.value);
        },
      
      
        updateLocation: function(){
          //console.log("Content in Location Selection:",content);
            //this.selectedLocation = content;
            this.nodeList = this.nodes;
            this.selectedType = {},
            this.selectedCategory = {},
            this.selectedNode = {}
           if(this.selectedLocation){
            let nodeList = this.nodeList;
            let filterednodeList = nodeList.filter(item => {
              return item.locationDetails.id == this.selectedLocation.id;
            });
            this.nodeList = filterednodeList.length ? filterednodeList : [];
          } else {
            this.nodeList = this.nodes;
          }
        },
        updateNode: function(){
          if(this.selectedNode){
            this.selectedType = this.selectedNode.nodeType
            this.selectedCategory = this.selectedNode.nodeCategory;
            this.selectedLocation = this.selectedNode.locationDetails;
            this.content.node_id = this.selectedNode.id;
            this.content.node_name = this.selectedNode.name;
            console.log("Selected Node:",this.selectedNode);
          } else {
            this.content = {
              node_id: '',
              node_name: ''
            };
          }
        }
      },
  
  }
  </script>
  
  <style lang="css" scoped>
  .b-location .form-group {
    position: relative;
    margin-bottom: 0 !important;
  }
  .b-location .form-group .form-control{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 35px;
  }
  .b-location .form-group i{
    font-size: 24px;
    position: absolute;
    bottom: 0;
    right: 0px;
    cursor: pointer;
    height: 38px;
    vertical-align: middle;
    display: flex;
    align-items: center;
    width: 35px;
    padding-left: 5px;
    border-left: 1px solid #ced4da;
  }
  .b-location .multiselect__content-wrapper {
      width: auto !important;
  }
  </style>
  