const BASE_URL = window.location.protocol + '//' + window.location.host;
const APPLICATIONROLE = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/applicationrole';
//Lov
const LOV = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/lov'
// to create department
const DEPARTMENTS = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/departments';
const DEPARTMENT = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/department';
//to create locations
const ALLLOCATIONS =BASE_URL +'/api' +(window.baseUrl ? window.baseUrl : '')+'/alllocations';
const LOCATIONS = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/locations';
const LOCATION = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/location';
const LOCATION_TYPES = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/locationtypes';

const UOMS = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/uoms';
const SKU_TYPES = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/skutypes';
const SKU_ATTRIBUTE_META=BASE_URL+ '/api'+(window.baseUrl ? window.baseUrl : '')+'/skuAttribute';
const SKU_ATTRIBUTE_MAP=BASE_URL+ '/api'+(window.baseUrl ? window.baseUrl : '')+'/skuAttributeMap';

// To create SKUCATGORY
const SKUCATGORY = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/skucategory';
// to create skumasters
const SKUMASTER = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/sku';
const NODE_CATEGORIES = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/nodecategories';
const NODE_TYPES = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/nodetypes';
// To create Node
const NODE =BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/node';
const NODES= BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/nodes';
// to create Node SKUs
const NODESKUS = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/nodeskus'
const NODESKU = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/nodesku'
const NODESKUBYZONE = BASE_URL +'/api' +(window.baseUrl ? window.baseUrl : '')+'/nodeskubyzone'
const NODESKUBYPARENT = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/nodeskubyparentid'
const NODESKUTHROUGHFILE = BASE_URL +'/api' +(window.baseUrl ? window.baseUrl : '')+'/nodeskufromfile'
const SERACHNODESKUNAME = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/nodeskus/search'
const NODEBYSKUID = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/nodebyskuid'
const NODESKUSID = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/nodeskusid'
// To create users
const USERS = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/users'
const USER = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/user'
const SESSION_EXTEND = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/beat'
const FIELD_MAP = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/fieldmap'
const FILE_UPLOAD = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/file'
//user role
const USERROLE = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/userorganization'
const USERROLES = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/alluserorganization'
//to create Node Maps
const NODEMAPS = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/nodemaps'

const NODEMAP= BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/nodemap'

// to create department
const DESIGNATIONS = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/designations'
const DESIGNATION = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/designation'

const BEAT = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/beat';

const STAGE = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/stage';
const BOM = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/bom';
const NOTIFICATIONS = BASE_URL + '/api' + (window.baseUrl ? window.baseUrl : '') + '/me/notifications';
const TASKS = BASE_URL + '/api' + (window.baseUrl ? window.baseUrl : '') + '/me/tasks';

const PRODUCT_LIST = '/api' +(window.baseUrl ? window.baseUrl : '')+'/products';

const COUNTRIES = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/countries';
const CURRENCIES = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/currencies';
const PRODUCTSTAGE = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/productstage';

const MASTER_API = BASE_URL + '/api/master';

const USER_PREFERENCE = BASE_URL + '/api/master/userpreference';
const MASTER_NODE = BASE_URL + '/api/master/node';
const COMMONTASKS = BASE_URL + '/api' + (window.baseUrl ? window.baseUrl : '') + '/tasks';
const PACKAGES = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/packages';

//SideBar Grid List
const SIDEGRID = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/sidegridtree'

const NODEMAPSID = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/nodemapsid';
const CALENDAR = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/calendar';

const SUPPLIER_FROM_ACCESS = BASE_URL + '/api/master/user';

const ALLOCATE_CONSUMER_DISPATCH = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/allocateconsumerdispatch';

const LOV_TYPES = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/lovtypes';
const LOVS = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/lovs';
const JOB_META = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/jobs';
const JOB_DETAILS = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/jobdetails';
const STAGE_TEMPLATE = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/stagetemplate';
const USERNODEMAP = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/user/me/nodemaps'

const NOTIFICATION_ALERTS = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/notificationalerts';

const PRODUCTS = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/modules';

const ORGANIZATION = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/org';

const TEMPLATE = BASE_URL + '/api' +(window.baseUrl ? window.baseUrl : '')+'/template';


export {
    BEAT,
    BASE_URL,
    STAGE,
    BOM,
    USERS,
    USER,
    LOV,
    SESSION_EXTEND,
    FIELD_MAP,
    FILE_UPLOAD,
    APPLICATIONROLE,
    USERROLE,
    USERROLES,
    DEPARTMENTS,
    DEPARTMENT,
    DESIGNATION,
    DESIGNATIONS,
    ALLLOCATIONS,
    LOCATIONS,
    LOCATION,
    LOCATION_TYPES,
    UOMS,
    SKU_TYPES,
    SKUCATGORY,
    SKUMASTER,
    NODE_CATEGORIES,
    NODE_TYPES,
    SKU_ATTRIBUTE_META,
    SKU_ATTRIBUTE_MAP,
    NODE,
    NODES,
    NODESKUS,
    NODESKU,
    NODEMAPS,
    NODEMAP,
    NODESKUBYZONE,
    NODESKUBYPARENT,
    NODESKUTHROUGHFILE,
    SERACHNODESKUNAME,
    PRODUCT_LIST,
    NOTIFICATIONS,
    TASKS,
    COUNTRIES,
    CURRENCIES,
    PRODUCTSTAGE,
    USER_PREFERENCE,
    MASTER_NODE,
    MASTER_API,
    SIDEGRID,
    COMMONTASKS,
    PACKAGES,
    NODEMAPSID,
    NODEBYSKUID,
    CALENDAR,
    NODESKUSID,
    SUPPLIER_FROM_ACCESS,
    ALLOCATE_CONSUMER_DISPATCH,
    LOV_TYPES,
    LOVS,
    JOB_META,
    JOB_DETAILS,
    STAGE_TEMPLATE,
    USERNODEMAP,
    NOTIFICATION_ALERTS,
    PRODUCTS,
    ORGANIZATION,
    TEMPLATE
}